/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_USER from "@/graphql/queries/user/getUser";
import normalizeUser from "@/model/User";
import { withoutNulls } from "@/utils/withoutNulls";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import * as Sentry from "@sentry/nextjs";
import { useAuth0 } from "@auth0/auth0-react";

export default function useCurrentUser() {
  const {
    isAuthenticated,
    isLoading: authLoading,
    user: auth0User,
  } = useAuth0();

  const [getUser, { data: raw, loading: userLoading, error }] =
    useLazyQuery(GET_USER);

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.user);
    return data && normalizeUser(data);
  }, [raw]);

  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, [getUser, isAuthenticated]);

  Sentry.setUser({ id: normalized?.id, email: normalized?.email });

  return {
    data: normalized,
    loading: authLoading || userLoading,
    error,
    auth0User,
    isAuthenticated,
  };
}
